/*
 * @Author: fangjun
 * @Date: 2023-04-20 17:11:41
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2024-08-16 11:01:58
 * @FilePath: \src\router\index.js
 */
import NProgress from '@/utils/progress'
import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout/index.vue'
import { rolesMap } from '@/config/constant'
import { shallowRef } from 'vue'
export const constantRoutes = [
  {
    path: '/login',
    name: '登录',
    component: () => import('@/views/login/index.vue'),
    hidden: true,
    meta: {
      title: '登录'
    }
  },
  {
    path: '/forgetPassword',
    name: '忘记密码',
    component: () => import('@/views/forgetPassword/index.vue'),
    hidden: true,
    meta: {
      title: '忘记密码'
    }
  },
  {
    path: '/',
    name: '',
    redirect: '/dynamicIndex',
    component: shallowRef(Layout),
    hidden: true,
    meta: {
      title: ''
    }
  },
  {
    path: '/inspectScreen',
    name: '巡检大屏',
    component: () => import('@/views/screen/index.vue'),
    hidden: true,
    meta: {
      title: '巡检大屏'
    }
  }
]

// 动态菜单，主要根据用户角色权限
export const asyncRoutes = []

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes,
  scrollBehavior: () => ({ y: 0 })
})

export default router
