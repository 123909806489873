<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
      v-if="isMobile"
    />
    <div class="right-menu">
      <div class="customArea-select">
        <el-select
          v-model="customAreaId"
          placeholder="所有客户区域"
          v-if="isParentCustomArea && hasPermission"
          clearable
          @change="selectCustomArea"
          filterable
        >
          <el-option
            v-for="item in customAreas"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="dingdang-box">
        <el-badge
          :value="redPoint > 99 ? '99+' : redPoint"
          :class="['ling-dang-box', redPoint > 0 ? 'dingdangMove' : '']"
          @click="toggleMessageDrawer"
        >
          <!-- <img src="~@/assets/images/dingdang.png" alt="" class="dingdang" /> -->
          <SvgIcon icon-class="system-dingdang" class="dingdang" style="color: white;"></SvgIcon>
        </el-badge>
      </div>
      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
        size="medium"
      >
        <div class="avatar-wrapper">
          <img :src="avatarImage" class="user-avatar" />
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="openUserCenterDialog"
              >个人中心</el-dropdown-item
            >
            <el-dropdown-item @click="openModifyPasswordDialog"
              >修改密码</el-dropdown-item
            >
            <el-dropdown-item divided @click="logout"
              >注销登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <user-center :dialog="ucDialog" />
    <modify-password :dialog="mpDialog" />
  </div>
</template>
  
<script>
import Hamburger from "@/components/Hamburger";
import {
  computed,
  defineComponent,
  reactive,
  onMounted,
  toRefs,
  watch,
} from "vue";
import { useAppStore } from "@/store/modules/app";
import { ElMessageBox, ElNotification } from "element-plus";
import { useRoute } from "vue-router";
import { useUserStore } from "@/store/modules/user";
import { useWsStore } from "@/store/modules/websocket";
import Defult_Avatar from "@/assets/images/avatar_default.png";
import { useNotifyStore } from "@/store/modules/notify";
import UserCenter from "@/views/userCenter/index.vue";
import ModifyPassword from "@/views/userCenter/dialog/modifyPassword.vue";
import { emitter } from "@/utils/mitt";
import { getCustomArea } from "@/api/home/index";
import { useScreenStore } from '@/store/modules/screen'
export default defineComponent({
  name: "NavBar",
  props: ["device"],
  components: {
    Hamburger,
    UserCenter,
    ModifyPassword,
  },
  setup(props) {
    const state = reactive({
      redPoint: "",
      ucDialog: {
        visible: false,
      },
      mpDialog: { visible: false },
      customAreas: [],
      customAreaId: null,
    });
    const notifyStore = useNotifyStore();
    const wsStore = useWsStore();
    const route = useRoute();
    const userStore = useUserStore();
    const appStore = useAppStore();
    const screenStore = useScreenStore()
    const sidebar = computed(() => {
      return appStore.sidebar;
    });
    const toggleSideBar = () => {
      appStore.TOGGLE_SIDEBAR();
    };
    const logout = () => {
      ElMessageBox.confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          userStore.logout().then(() => {
            location.href = "/login";
          });
        })
        .catch(() => {});
    };
    const isMobile = computed(() => {
      return props.device === "mobile";
    });
    const avatarImage = computed(() => {
      return userStore.avatar || Defult_Avatar;
    });
    const watchWsReady = () => {
      wsStore.websocket.addEventListener("message", (e) => {
        let res = JSON.parse(e.data);
        let { type, total, data } = res;
        if (type === "WARNING") {
          let redPoint = total === 0 ? "" : total;
          state.redPoint = redPoint;
        } else if (type === 'EVERYDAY_TASK') {
          dealNotify(data)
        }
      });
    };
    const notifyInstanceMap = {}
    const dealNotify = (arr) => {
      arr.map((item, idx) => {
        let message = ''
        if (item.status == '0') {
          message = `系统检测到，“${item.name}”机器人当前离线，无法执行今天${item.time}的巡检任务，请您查看机器人是否未开机，故障请联系售后人员。`
        } else {
          message = `今天${item.time}，“${item.name}”机器人开始执行巡检任务，如果外面正在下雨或起大雾，请您注意取消任务。`
        }
        if (!notifyInstanceMap[item.snCode]) {
          notifyInstanceMap[item.snCode] = {
            timestamp: new Date().getTime(),
            message: message,
          }
          let timeout = setTimeout(() => {
            let instance = ElNotification.info({
              title: '通知',
              message: message,
              offset: 100,
              duration: 60 * 1000,
              onClose: () => {
                if(notifyInstanceMap[item.snCode]) {
                  notifyInstanceMap[item.snCode].instance?.close()
                  notifyInstanceMap[item.snCode].instance = null
                  delete notifyInstanceMap[item.snCode]
                }
              }
            })
            notifyInstanceMap[item.snCode].instance = instance
            clearTimeout(timeout)
          }, 100 + 100 * idx)
        }
      })

    }
    const watchNotify = () => {
      setInterval(() => {
        let currentTimeStamp = new Date().getTime()
        for (var key in notifyInstanceMap) {
          let notify = notifyInstanceMap[key]
          if ((currentTimeStamp - notify.timestamp) > 60 * 1000) {
            console.log(currentTimeStamp - notify.timestamp);
            notify.instance?.close()
            notify.instance = null
            delete notifyInstanceMap[key]
          }
        }
      }, 1000)
    }
    const toggleMessageDrawer = () => {
      notifyStore.toggle();
    };
    const openUserCenterDialog = () => {
      state.ucDialog.visible = true;
    };
    const openModifyPasswordDialog = () => {
      state.mpDialog.visible = true;
    };
    const notifyIsShow = computed(() => {
      return notifyStore.isShow;
    });
    const isParentCustomArea = computed(() => {
      return userStore.user.parentCustomArea;
    });
    const hasPermission = computed(() => {
      let {title} = route.meta
      return title === "首页" || title === "监控大屏";
    });
    const getAreas = () => {
      getCustomArea().then((res) => {
        let childrenArea = res.data || [];
        state.customAreas = [
          {
            id: userStore.customArea.id,
            name: userStore.customArea.name,
            longitude: userStore.customArea.longitude,
            latitude: userStore.customArea.latitude,
          },
          ...childrenArea,
        ];
        userStore.setCustomAreaList(state.customAreas)
      });
    };
    watch(
      () => state.redPoint,
      (v) => {
        if (notifyIsShow.value) {
          emitter.emit("redPointChange");
        }
      }
    );
    const customAreaMap = computed(() => {
      let res = {}
      for(var i=0;i<state.customAreas.length;i++) {
        let item = state.customAreas[i]
        let {id} = item
        res[id] = item
      }
      return res
    })
    const changeAreas = (id) => {
      userStore.setCustomArea(id)
      // websocket切换
      let str = JSON.stringify({
        type: "CUSTOM_AREA",
        customAreaId: id,
      });
      wsStore.websocket.send(str);
    }
    const goMapCenter = (id) => {
      let area = customAreaMap.value[id] || {
              id: userStore.customArea.id,
              name: userStore.customArea.name,
              longitude: userStore.customArea.longitude,
              latitude: userStore.customArea.latitude
            }
            emitter.emit('moveMap', area)
    }
    const selectCustomArea = (v) => {
        changeAreas(v)
        if(route.meta.title === "监控大屏") {
          screenStore.setAmr(null)
          goMapCenter(v)
        }
        emitter.emit('selectCustomArea')
    }
    onMounted(() => {
      watchNotify()
      watchWsReady();
      if (isParentCustomArea.value) {
        getAreas();
      }
    });
    return {
      ...toRefs(state),
      sidebar,
      toggleSideBar,
      logout,
      isMobile,
      avatarImage,
      toggleMessageDrawer,
      openUserCenterDialog,
      openModifyPasswordDialog,
      notifyIsShow,
      getAreas,
      isParentCustomArea,
      hasPermission,
      customAreaMap,
      selectCustomArea
    };
  },
});
</script>
<style lang="scss" scoped>
// 红点css动效

@keyframes upAnimation {
  0% {
    transform: rotate(0deg);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  10% {
    transform: rotate(-12deg);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  20% {
    transform: rotate(12deg);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  28% {
    transform: rotate(-10deg);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  36% {
    transform: rotate(10deg);
    transition-timing-function: cubic-bezier(0.755, 0.5, 0.855, 0.06);
  }
  42% {
    transform: rotate(-8deg);
    transition-timing-function: cubic-bezier(0.755, 0.5, 0.855, 0.06);
  }
  48% {
    transform: rotate(8deg);
    transition-timing-function: cubic-bezier(0.755, 0.5, 0.855, 0.06);
  }
  52% {
    transform: rotate(-4deg);
    transition-timing-function: cubic-bezier(0.755, 0.5, 0.855, 0.06);
  }
  56% {
    transform: rotate(4deg);
    transition-timing-function: cubic-bezier(0.755, 0.5, 0.855, 0.06);
  }
  60% {
    transform: rotate(0deg);
    transition-timing-function: cubic-bezier(0.755, 0.5, 0.855, 0.06);
  }
  100% {
    transform: rotate(0deg);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}
.navbar {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  overflow: hidden;
  background: var(--el-color-primary);
  width: 100%;
  z-index: 1;

  .hamburger-container {
    display: flex;
    align-items: center;
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .right-menu {
    float: right;
    height: 100%;
    display: flex;
    margin-right: 40px;
    align-items: center;
    &:focus {
      outline: none;
    }
    .customArea-select {
      display: flex;
      align-items: center;
      margin-right: 20px;
      width: 10vw;
    }
    .dingdang-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      height: 30px;
      width: 30px;
      border: 1px solid #fff;
      border-radius: 100%;
      cursor: pointer;
      .dingdang {
        
      }
      .dingdangMove {
        animation-name: upAnimation;
        transform-origin: center bottom;
        animation-duration: 2s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-delay: 0.5s;
      }
    }

    .avatar-wrapper {
      display: flex;
      align-items: center;
      .user-avatar {
        width: 34px;
        height: 34px;
        border-radius: 100%;
      }
    }
  }
}
</style>
  